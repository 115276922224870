* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  color: #b5b5b6;
  font-family: "Courier", "monospace";
}
.screen {
  background-color: #1d1d20;
  width: 100vw;
  height: 120vh;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 0.2rem;
}

@media screen and (min-height: 600px) {
  .screen {
    height: 100vh;
  }
}
.deco1 {
  transform: rotate(180deg);
  position: absolute;
  top: 0px;
  left: 0px;
  width: 8rem !important;
  height: 8rem !important;
}

.deco1 {
  transform: rotate(180deg);
  position: absolute;
  top: 0px;
  left: 0px;
  width: 8rem !important;
  height: 8rem !important;
}
.deco2 {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 8rem !important;
  height: 8rem !important;
}

.container {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  overflow: hidden;
  box-sizing: border-box;
}

.header {
  padding: 2rem 0.5rem;
  width: 100%;
  height: 6rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon {
  width: 2rem;
  height: 2rem;
}
.icon:hover {
  cursor: pointer;
}

.header_icons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
  z-index: 999;
}

.sub_content {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.content {
  width: 100%;
  box-sizing: border-box;
  padding: 1.5rem;
  display: grid;
  grid-template-columns: auto;
  align-items: center;
  gap: 1.5rem;
}
.content_1 {
  min-height: 40vh !important;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.content_2 {
  position: relative;
  padding: 1rem;
  min-height: 50%;
}

.deco3 {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 60px !important;
  height: 60px !important;
  border-top: 2px solid red;
  border-left: 2px solid red;
  border-top-left-radius: 10px;
}

.cnt {
  padding: 1rem;
  position: relative;
  width: 100%;
  height: auto;
}
.biblio_container {
  line-height: 1.7rem !important;
}
.text_anim {
  width: 50vw;
  height: 5rem;
  box-sizing: border-box;
  text-align: left;
}

.title {
  font-size: 2rem;
  padding: 1rem 0rem;
}
.v_deco {
  position: absolute;
  top: 0 !important;
  left: 0px !important;
  border-radius: 10px;
  height: 100% !important;
  border: 3px solid #ff2f2fb6;
}

.project {
  display: inline-block;
  position: relative;
  transition: all 0.2s ease-in-out;
}
.pro_desc{
  line-height:2rem !important;
}
@media screen and (min-width: 700px) {
  .text_anim {
    width: 22rem;
    height: 6rem;
  }
  .project:hover {
    padding: 0rem 1rem;
    cursor: pointer;
  }
}
.pro_deco {
  width: 100%;
  height: 0px;
  border: 2px solid #ff2f2fb6;
  border-radius: 10px;
}
.p_title {
  color: #ff2f2fb6;
  padding: 1rem 0rem;
}
.project_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.project_container li {
  display: inline;
  list-style: none;
}

.links {
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
}
.link{
  text-decoration: underline;
  padding-top: 10px;
  cursor: pointer;
}
.tech{
  font-weight: bold;
  color: #ff2f2fb6;
}
@media screen and (min-width: 500px) {
  .deco1,
  .deco2 {
    width: 14rem !important;
    height: 14rem !important;
  }
  .icon {
    width: 3rem;
    height: 3rem;
  }
  .deco3 {
    width: 100px !important;
    height: 100px !important;
  }
}
@media screen and (min-width: 700px) {
  .text_anim {
    width: 22rem;
    height: 6rem;
  }
}
@media screen and (min-width: 900px) {
  .container {
    height: 700px;
  }
  .header {
    padding: 2rem 2rem;
  }
  .content {
    grid-template-columns: 70% 30%;
    gap: 1rem;
  }
}
@media screen and (min-width: 1280px) {
  .container {
    width: 1280px;
  }
}

.video_container{
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  background-color: #1d1d20d2;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video_content{
  width: 60%;
  height: 50%;
  border: 2px solid #ff2f2fb6;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}
.messagin_video{
  width: 100% !important;
  height: 100% !important;
}
.hide{
  display: none;
}

@media screen and (max-width: 400px) {
  .video_content {
    width: 90vw;
    height: 96vh;
    padding: 0;
  }
}
@media screen and (max-width: 1000px) {
  .video_content {
    width: 80%;
    height: auto;
    padding: 0;
  }
}